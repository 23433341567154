@import url(https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/3.3.7/css/bootstrap.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);

$primary-colour: #18BC9C;
$secondary-colour: #80B3FF;
$secondary-dark-colour: #A2122F;
$tc-blue: #016997;

body {
  overflow-x: hidden;
}

.header-img {
  background: $secondary-colour;
  border-radius: 50%;
}
p {
  font-size: 20px;
  &.small {
    font-size: 16px;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight: 700;
}

hr {
  &.star {
    margin: 25px auto 30px;
    padding: 0;
    max-width: 250px;
    border: 0;
    border-top: solid 5px;
    text-align: center;
  }
  &.star:after {
    content: "\f005";
    display: inline-block;
    position: relative;
    top: -.8em;
    padding: 0 .25em;
    font-family: FontAwesome;
    font-size: 2em;
  }
}

.btn-outline {
  margin-top: 15px;
  border: solid 2px #fff;
  font-size: 20px;
  color: #fff;
  background: 0 0;
  transition: all .3s ease-in-out;
}

.primary-light {
  p, h2, h3, a {
    color: $primary-colour;
  }
  .star {
    border-color: $primary-colour;
    &:after {
      color: $primary-colour;
      background-color: #fff;
    }
  }
  .btn {
    border: solid 2px $primary-colour;
    &:hover, &:focus, &:active, &.active {
      border: solid 2px $primary-colour;
      color: #fff;
      background: $primary-colour;
    }
  }
}

.primary-dark {
  background-color: $primary-colour;
  p, h2, h3 {
    color: #fff;
  }
  .star {
    border-color: #fff;
    &:after {
      color: #fff;
      background-color: $primary-colour;
    }
  }
  .btn {
    border: solid 2px #fff;
    &:hover, &:focus, &:active, &.active {
      border: solid 2px #fff;
      color: $primary-colour;
      background: #fff;
    }
  }
}


.img-centered {
  margin: 0 auto;
}

header {
  text-align: center;
  color: #fff;
  background: $primary-colour;
  .container {
    padding-top: 100px;
    padding-bottom: 50px;
  }
  img {
    display: block;
    margin: 0 auto 20px;
  }
  .intro-text {
    .name {
      display: block;
      text-transform: uppercase;
      font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
      font-size: 2em;
      font-weight: 700;
    }
    .skills {
      font-size: 1.25em;
      font-weight: 300;
    }
  }
  @media (min-width: 768px) {
    .container {
      padding-top: 200px;
      padding-bottom: 100px;
    }
    .intro-text {
      .name {
        font-size: 4.75em;
      }
      .skills {
        font-size: 1.75em;
      }
    }
  }
}

@media (min-width: 768px) {
  .navbar-fixed-top {
    padding: 25px 0;
    -webkit-transition: padding .3s;
    -moz-transition: padding .3s;
    transition: padding .3s;
    .navbar-brand {
      font-size: 2em;
      -webkit-transition: all .3s;
      -moz-transition: all .3s;
      transition: all .3s;
    }
    &.navbar-shrink {
      padding: 10px 0;
      .navbar-brand {
        font-size: 1.5em;
      }
    }
  }
}

.navbar {
  text-transform: uppercase;
  font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight: 700;
  a:focus {
    outline: 0;
  }
  .navbar-nav {
    letter-spacing: 1px;
    li a:focus {
      outline: 0;
    }
  }
  .tcs-enquiry-modal {
    .tcs-centre {
      color: $primary-colour;
    }
    .tcs-enquiry-button {
      background: none;
      color: $primary-colour;
      @media (min-width: 768px) {
        color: white;
        background: $primary-colour;
        border: solid 2px $primary-colour;
        &:hover {
          color: $primary-colour;
          background: transparent;
          text-decoration: none !important;
        }
      }
    }
    .tcs-submit {
      button {
        background: $primary-colour;
        border: solid 2px $primary-colour;
        &:hover {
          color: $primary-colour;
          background: transparent;
          text-decoration: none !important;
        }
      }
    }
  }
}

.navbar-default, .navbar-inverse {
  border: 0;
}

section {
  padding: 100px 0;
  h2 {
    margin: 0;
    font-size: 3em;
  }
  &.success {
    color: #fff;
    background: $primary-colour;
  }
}

@media (max-width: 767px) {
  section {
    padding: 75px 0;
    &.first {
      padding-top: 75px;
    }
  }
}

a {
  color: $primary-colour;
  outline: 0;
  &:hover, &:focus, &:active, &.active {
    outline: 0;
    color: $primary-colour;
  }
}

.tc-link {
  color: $tc-blue;
  &:hover, &:focus, &:active, &.active {
    outline: 0;
    color: $tc-blue;
    text-decoration: none;
  }
}

.socket-container {
  max-width: 970px;
  margin: 0 auto;
  .tcs-box {
    background-color: $primary-colour;
    box-shadow: None;
    color: white;
    .tcs-name {
      border: 1px solid $primary-colour;
      border-radius: 0 0 3px 3px;
      transition: all .5s ease;
      padding: 5px 0;
    }

    &:hover {
      color: $primary-colour;
      background-color: white;
      text-decoration: none !important;
    }
  }
  .tcs-modal {
    h2 {
      color: $primary-colour;
    }
    .tcs-skills {
      span {
        background: $primary-colour;
      }
    }
    .tcs-extra {
      button {
        background: #fff;
        color: $primary-colour;
        border: solid 2px $primary-colour;
        &:hover, &:focus, &:active, &.active {
          border: solid 2px $primary-colour;
          color: #fff;
          background: $primary-colour;
        }
      }
    }
  }
}

section#contact {
  .tcs-submit {
    button {
      background: #fff;
      color: $primary-colour;
      border: solid 2px $primary-colour;
      &:hover, &:focus, &:active, &.active {
        border: solid 2px $primary-colour;
        color: #fff;
        background: $primary-colour;
      }
    }
  }
  .tcs-field {
    padding: 8px 0;
  }
}

.tc-desc {
  color: $secondary-colour !important;
}

footer {
  color: #fff;
  h3 {
    margin-bottom: 30px;
  }
  .footer-above {
    padding-top: 50px;
    background-color: $secondary-colour;
  }
  .footer-col {
    margin-bottom: 50px;
  }
  .footer-below {
    padding: 25px 0;
    background-color: $secondary-colour;
  }
}

h3 .tutor-signup {
  font-size: 28px;
  font-weight: 600 !important;
  padding-bottom: 20px;
}

.btn-social {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 2px solid #fff;
  border-radius: 100%;
  text-align: center;
  font-size: 20px;
  line-height: 45px;
}

.btn {
  &:focus, &:active, &.active {
    outline: 0;
  }
}

.scroll-top {
  z-index: 1049;
  position: fixed;
  right: 2%;
  bottom: 2%;
  width: 50px;
  height: 50px;
  .btn {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    font-size: 20px;
    line-height: 28px;
    &:focus {
      outline: 0;
    }
  }
}

.navbar-nav > .active > a {
  background-color: $secondary-dark-colour !important;
}
